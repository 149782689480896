import React, { Component } from "react";

// Import Breadcrumb
import RCDisplayPage from "../../components/Common/RCDisplayPage";
// Constants
import { API_WEB_URLS } from "../../constants/constAPI";
// Store
import { compose } from "recompose";
import { container } from "../../store/Containers/cntCommon";
import {
  Fn_FillListData,
  togglemodal,
  toggleDeleteConfirm,
  toggleDeleteSuccess,
  Fn_DeleteData,
  Fn_GetReport,
} from "../../store/functions";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Progress,
  Modal,
} from "reactstrap";
import Loader from "./loader";

class pageList_MonthlyStatusReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      selectedFormData: {},
      gridData: [],
      confirm_alert: false,
      success_dlg: false,
      dynamic_title: "",
      dynamic_description: "",
      dates: ["date", 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      statuses: ["status", "P", "A", "P", "P", "A", "P", "A", "P", "P", "A"],
      employee: [],
      loading: false,
      F_Department: 0,
      FirmName: "",
    };
    this.obj = this;
    this.breadCrumbTitle = "Masters";
    this.breadCrumbItem = "Customer Details";
    this.modalTitle = "Customer Details";
    this.rtPage_Add = "add-customermaster";
    this.rtPage_Edit = "edit-customermaster";
    this.API_URL = API_WEB_URLS.MASTER + "/0/token/EmpId";

    // Event Binding
    this.btnAdd_onClick = this.btnAdd_onClick.bind(this);
    this.btnEdit_onClick = this.btnEdit_onClick.bind(this);
    this.btnDelete_onClick = this.btnDelete_onClick.bind(this);
    this.btnSave_onClick = this.btnSave_onClick.bind(this);
  }

  componentDidMount() {
    Fn_FillListData(
      this.obj,
      "Department",
      "Masters/0/token/DepartmentMaster/Id/2"
    );
    const obj = localStorage.getItem("FirmName");
    this.setState({ FirmName: obj });
    // let vformData = new FormData();
    // vformData.append("Year", 2024);
    // vformData.append("Month", 5);
    // Fn_GetReport(
    //   this.obj,
    //   { arguList: { id: 0, formData: vformData } },
    //   "GetDatesMonthlyReport/0/token",
    //   "dates",
    //   true

    // );
    // Fn_GetReport(
    //   this.obj,
    //   { arguList: { id: 0, formData: vformData } },
    //   "GetMonthlyReport/0/token",
    //   "statuses",
    //   true
    // );

    // Fn_GetReport(
    //   this.obj,
    //   { arguList: { id: 0, formData: vformData } },
    //   "GetEmployeeMonthlyReport/0/token",
    //   "employee",
    //   true

    // );
  }

  btnAdd_onClick(event, values) {
    this.props.history.push(`${this.rtPage_Add}`);
  }

  btnEdit_onClick(formData) {
    this.props.history.push(`${this.rtPage_Edit}/${formData.Id}`, {});
  }

  btnSave_onClick() {
    this.setState({ loading: true });
    const obj = JSON.parse(localStorage.getItem("F_CompanyMaster"));
    this.setState({ gridData: [{}], employee: [{}], statuses: [{}] });

    let vformData = new FormData();
    vformData.append("Year", this.state.YearId);
    vformData.append("Month", this.state.MonthId);
    vformData.append("F_Department", this.state.F_Department);
    Fn_GetReport(
      this.obj,
      { arguList: { id: 0, formData: vformData } },
      "GetDatesMonthlyReport/0/token",
      "dates",
      true
    );

    Fn_GetReport(
      this.obj,
      { arguList: { id: 0, formData: vformData } },
      "GetEmpIdMonthlyReport/0/token",
      "gridData",
      true
    );

    const obj2 = JSON.parse(localStorage.getItem("authUser"));

    if (obj2.IsLegalLogin  ==  1) {
    Fn_GetReport(
      this.obj,
      { arguList: { id: 0, formData: vformData } },
      "GetEmployeeMonthlyReportLegal/0/token",
      "employee",
      true,
      "ss"
    );
    
  }
  else {
    Fn_GetReport(
      this.obj,
      { arguList: { id: 0, formData: vformData } },
      "GetEmployeeMonthlyReport/0/token",
      "employee",
      true
    );
  }
  }

  btnDelete_onClick(formData) {
    Fn_DeleteData(this.obj, formData.Id, this.API_URL, this.API_URL + "/Id/0");
  }
  render() {
    const { dates, statuses, employee, gridData } = this.state;
  
    console.log("Render Grid Data:", gridData);
    console.log("Render Statuses:", statuses);
    console.log("Render Employee:", employee);
  
    // Flatten the dates object array into a single array
    const flatDates = Object.entries(dates[0] || {}).map(([key, value]) => ({
      date: key,
      day: value,
    }));
  
    // Determine the maximum length of dates and status values
    const maxLength = Math.max(
      flatDates.length,
      ...statuses.map(
        status => Object.keys(status).filter(key => key.startsWith("D")).length
      )
    );
  
    // Pad the dates to ensure the length matches the maximum length
    const paddedDates = [
      ...flatDates,
      ...Array(maxLength - flatDates.length).fill({ date: "", day: "" }),
    ];
  
    // Split gridData into chunks of 3
    const chunkedGridData = [];
    for (let i = 0; i < gridData.length; i += 3) {
      chunkedGridData.push(gridData.slice(i, i + 3));
    }
    const monthNames = ["", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const month = monthNames[this.state.MonthId] || "";
    let currentDepartment = null;
  
    return (
      <div className="page-content">
        {/* <button onClick={() => window.print()}>Print</button> */}
        {this.state.loading ? <Loader /> : null}
        <Row className="no-print">
          <Col lg="12">
            <Card>
              <CardBody>
                <Row>
                  <Col sm="1" className="mb-3">
                    <label htmlFor="firstName" className="col-form-label">
                      {" "}
                      Select Year
                    </label>
                  </Col>
                  <Col sm="2">
                    <select
                      name="YearId"
                      label=""
                      onChange={e => this.setState({ YearId: e.target.value })}
                      value={this.state.YearId}
                      errorMessage="Select YearId "
                      validate={{ required: { value: true } }}
                      type="select"
                      className="form-select"
                    >
                      <option value={-1} defaultValue label={"Select"} />
                      <option value={2023} label={"2023"} />
                      <option value={2024} label={"2024"} />
                      <option value={2025} label={"2025"} />
                    </select>
                  </Col>
  
                  <Col sm="1" className="mb-3">
                    <label htmlFor="firstName" className="col-form-label">
                      {" "}
                      Select Month
                    </label>
                  </Col>
                  <Col sm="2">
                    <select
                      name="MonthId"
                      id="MonthId"
                      label=""
                      onChange={e => this.setState({ MonthId: e.target.value })}
                      value={this.state.MonthId}
                      errorMessage="Select MonthId "
                      validate={{ required: { value: true } }}
                      type="select"
                      className="form-select"
                    >
                      <option value={-1} defaultValue label={"Select"} />
                      <option value={1} label={"Jan"} />
                      <option value={2} label={"Feb"} />
                      <option value={3} label={"Mar"} />
                      <option value={4} label={"Apr"} />
                      <option value={5} label={"May"} />
                      <option value={6} label={"Jun"} />
                      <option value={7} label={"Jul"} />
                      <option value={8} label={"Aug"} />
                      <option value={9} label={"Sep"} />
                      <option value={10} label={"Oct"} />
                      <option value={11} label={"Nov"} />
                      <option value={12} label={"Dec"} />
                    </select>
                  </Col>
  
                  <Col sm="4" className="mb-3">
                    <label htmlFor="maritalStatus" className="col-form-label">
                      Department
                    </label>
                  </Col>
                  <Col sm="6">
                    <select
                      name="F_Department"
                      id="F_Department"
                      label=""
                      onChange={e =>
                        this.setState({ F_Department: e.target.value })
                      }
                      value={
                        this.state.F_Department === null
                          ? "-1"
                          : this.state.F_Department
                      }
                      type="select"
                      className="form-select"
                    >
                      <option value={0} defaultValue label={"All Department"} />
                      {this.state.Department
                        ? this.state.Department.map((option, key) => (
                            <option
                              key={option.Id}
                              value={option.Id}
                              label={option.Name}
                            />
                          ))
                        : null}
                    </select>
                  </Col>
  
                  <Col sm="3">
                    <Button
                      type="button"
                      onClick={this.btnSave_onClick}
                      color="primary"
                      className="mr-1 waves-effect waves-light"
                    >
                      View
                    </Button>
  
                    <Button
                      type="button"
                      onClick={() => window.print()}
                      color="primary"
                      className="mr-1 waves-effect waves-light"
                    >
                      Print
                    </Button>
  
                    <></>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
  
        <Row>
          <div >
            <div style={{ float: 'left', width: '100', marginLeft: 10 }}>
              <h4>Month: {month}</h4>
            </div>
            <div style={{ float: 'left', marginLeft: 360 }}>
              <h4>
                FirmName: {this.state.FirmName}
              </h4>
            </div>
            <div style={{ float: 'right', marginRight: 100, width: '100' }}>
              <h4>
                Year: {this.state.YearId}
              </h4>
            </div>
          </div>
        </Row>
  
        <div style={{ overflowX: "auto" }}>
          {chunkedGridData.map((chunk, chunkIndex) => (
            <div key={chunkIndex} className="page-break">
              {chunk.map((dataEmpId, index) => {
                // Filter statuses for the current employee
                const employeeStatuses = statuses.filter(
                  status => status.F_EmployeeMaster == dataEmpId.Id
                );
  
                const employeeData = employee.find(
                  emp => emp.F_EmployeeMaster == dataEmpId.Id
                );
  
                // If no statuses are found for the employee, return null
                if (employeeStatuses.length === 0) return null;
  
                // Check if the department has changed
                const isDepartmentChanged = currentDepartment !== employeeData.Department;
                currentDepartment = employeeData.Department;
  
                return (
                  <div key={index} >
                    <div style={{ margin: "20px 0", width: "100%" }}>
                      <div>Department: {employeeData.Department}</div>
                      <div>
                        <div style={{ float: "left" }}>
                          <h6>Employee: {employeeData.Employee}</h6>
                        </div>
                        <div style={{ float: "left", marginLeft: 20 }}>
                          <h6>
                            <div style={{ width: "70px" }}>
                              Total Work Duration:{" "}
                            </div>{" "}
                            {employeeData.TotalWorkDuration}{" "}
                          </h6>
                        </div>
                        <div style={{ float: "left", marginLeft: 20 }}>
                          <h6>
                            <div style={{ width: "40px" }}>Month Days: </div>
                            {employeeData.PaidDays}
                          </h6>
                        </div>
                        <div style={{ float: "left", marginLeft: 20 }}>
                          <h6>
                            <div style={{ width: "70px" }}>Present: </div>{" "}
                            {employeeData.Present}
                          </h6>
                        </div>
                        <div style={{ float: "left", marginLeft: 20 }}>
                          <h6>
                            <div style={{ width: "70px" }}>Absent: </div>
                            {employeeData.Absent}
                          </h6>
                        </div>
                        <div style={{ float: "left", marginLeft: 20 }}>
                          <h6>
                            <div style={{ width: "70px" }}>HalfDays: </div>{" "}
                            {employeeData.HalfDays}
                          </h6>
                        </div>
                        <div style={{ float: "left", marginLeft: 20 }}>
                          <h6>
                            <div style={{ width: "70px" }}>WeeklyOff: </div>
                            {employeeData.WeeklyOff}
                          </h6>
                        </div>
                        <div style={{ float: "left", marginLeft: 20 }}>
                          <h6>
                            <div style={{ width: "70px" }}>Holidays: </div>
                            {employeeData.Holidays}
                          </h6>
                        </div>
                        <div style={{ float: "left", marginLeft: 20 }}>
                          <h6>
                            <div style={{ width: "70px" }}>LeavesTaken: </div>
                            {employeeData.LeavesTaken}
                          </h6>
                        </div>
                        <div style={{ float: "left", marginLeft: 20 }}>
                          <h6>
                            <div style={{ width: "70px" }}>
                              AverageWorkingHrs:{" "}
                            </div>
                            {employeeData.AverageWorkingHrs}
                          </h6>
                        </div>
                        <div style={{ float: "right", marginRight: 20 }}>
                          <img
                            src={
                              employeeData.Photo == null
                                ? "https://w7.pngwing.com/pngs/205/731/png-transparent-default-avatar-thumbnail.png"
                                : API_WEB_URLS.IMAGEURL + employeeData.Photo
                            }
                            style={{ width: "50px", borderRadius: "50%" }}
                          />
                        </div>
                      </div>
                    </div>
  
                    <table className="styled-table">
                      <tbody>
                        <tr>
                          <th key="1">Days</th>
                          {paddedDates.map((dateObj, index) => (
                            <th key={index + 2}>{dateObj.day}</th>
                          ))}
                        </tr>
                        {employeeStatuses.map((filteredStatus, idx) => {
                          // Convert filteredStatuses to an array of status values
                          const statusValues = Object.entries(
                            filteredStatus
                          ).reduce((acc, [key, value]) => {
                            if (key.startsWith("D")) acc.push(value);
                            return acc;
                          }, []);
  
                          // Pad statuses to match the length of dates
                          const paddedStatuses = [
                            ...statusValues,
                            ...Array(maxLength - statusValues.length).fill(""),
                          ];
  
                          return (
                            <tr key={index + "-" + idx}>
                              <td>{filteredStatus.Name}</td>
                              {paddedStatuses.map((status, index) => (
                                <td key={index}>{status}</td>
                              ))}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                );
              })}
            </div>
          ))}
        </div>
        <style jsx>{`
          @media print {
            .no-print {
              display: none;
            }
          }
  
          .print-row {
            display: flex !important;
            justify-content: space-between !important;
          }
          .print-row .text-left {
            text-align: left !important;
          }
          .print-row .text-center {
            text-align: center !important;
          }
          .print-row .text-right {
            text-align: right !important;
          }
          
          @media print {
            @page {
              size: A4 landscape;
              margin: 10mm;
            }
            .page-content {
              width: 100%;
            }
            .styled-table {
              width: 100%;
              border-collapse: collapse;
            }
            .styled-table th,
            .styled-table td {
              border: 2px solid black;
              padding: 0px;
              text-align: center;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              font-size: 8px; /* Smaller font size for print */
              width: ${100 / (maxLength + 1)}%;
            }
            .styled-table th {
              background-color: #f2f2f2;
            }
            button {
              display: none;
            }
            .page-break {
              page-break-after: always;
            }
          }
          .styled-table {
            width: 100%;
            border-collapse: collapse;
          }
          .styled-table th,
          .styled-table td {
            border: 2px solid black;
            padding: 0px;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 8px; /* Default font size */
            width: ${100 / (maxLength + 1)}%;
          }
          .styled-table th {
            background-color: #f2f2f2;
          }
          .page-break {
            page-break-after: always;
          }
        `}</style>
      </div>
    );
  }
  
}

export default compose(container)(pageList_MonthlyStatusReport);
