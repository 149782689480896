const BASE = window.MANIFEST_DATA.data[0].BaseUrl;
const CustomerId = window.MANIFEST_DATA.data[0].CustomerId;


export const API_WEB_URLS = {
  BASE: BASE,
 //BASE: "http://apiatt.shinewellinnovation.com/api/V1/",
 //BASE: "http://apicrosscountry.shinewellinnovation.com/api/V1/",
  //IMAGEURL : "http://apiatt.shinewellinnovation.com/MemberImages/",
  IMAGEURL : "http://apiatt.shinewellinnovation.com/MemberImages/",

  LOGIN: "Authentication/Login",
  //
  MASTER: "Masters",
  CustomerId : CustomerId,


  CUSTOMER: "CustomerMaster",
  EmployeeMaster: "EmployeeMaster",
  EMPDEPARTMENTL: "InsertEmpDepartmentL",
  EMPBASIC: "InsertEmpBasic",
  COMPANY : "CompanyMaster",
   
  SHIFT : "ShiftMaster",
  RAWDATA : "InsertRawData",
  GLOBALOPTIONS : "UpdateGlobalOptions"

};
