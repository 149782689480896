import React  , {useRef} from "react";


function TableRowsAtt({rowsData , edit}) {

  
  const PaidDayss = useRef([]);
 debugger

  const handleFocus = (event) => event.target.select();

  const handler2 = (index ,evnt , indo) => {
  

      
  

      if (evnt.key == 'Enter') {
    evnt.preventDefault();
          PaidDayss.current[indo].focus();

         
      }

};

    return(
        
        rowsData.map((data, index)=>{
            const {Id , Name , PaidDays, EmpId , Month, Year , IsDone, FatherName, Department, Leaves, WorkingHolidays}= data;
            return(
                <tr key={index}>
                    <td>
                    <input
                          className="form-control"
                          type="text"
                          style={{"width" : "200px" , "height"  : "36px"}}
                          name="Name"
                       //   ref={(el) => (Descriptions.current[index] = el)}
                          value={Name}
                          
                          disabled
                        /> 
                    </td>


                    <td>
                    <input
                          className="form-control"
                          type="text"
                          style={{"width" : "200px" , "height"  : "36px"}}
                          name="FatherName"
                       //   ref={(el) => (Descriptions.current[index] = el)}
                          value={FatherName}
                          
                          disabled
                        /> 
                    </td>

                    <td>
                    <input
                          className="form-control"
                          type="text"
                          style={{"width" : "200px" , "height"  : "36px"}}
                          name="Department"
                       //   ref={(el) => (Descriptions.current[index] = el)}
                          value={Department}
                          
                          disabled
                        /> 
                    </td>
                    <td>
                    <input
                          className="form-control"
                          onChange={(evnt)=>(edit(index, evnt))}
                          type="number"
                          style={{"width" : "200px" , "height"  : "36px"}}
                          name="WorkingHolidays"
                          onKeyPress={(evnt)=>(handler2('PaidDays' ,evnt , index+1))}
                          value={WorkingHolidays} 
                          onFocus={handleFocus}
                        /> 
                    </td>
                    <td>
                    <input
                          className="form-control"
                          onChange={(evnt)=>(edit(index, evnt))}
                          type="number"
                          style={{"width" : "200px" , "height"  : "36px"}}
                          name="PaidDays"
                          ref={(el) => (PaidDayss.current[index] = el)}
                          onKeyPress={(evnt)=>(handler2('PaidDays' ,evnt , index+1))}
                          value={PaidDays} 
                          onFocus={handleFocus}
                        /> 
                    </td>
                    <td>
                    <input
                          className="form-control"
                          onChange={(evnt)=>(edit(index, evnt))}
                          type="text"
                          style={{"width" : "200px" , "height"  : "36px"}}
                          name="Leaves"
                          onKeyPress={(evnt)=>(handler2('PaidDays' ,evnt , index+1))}
                          value={Leaves} 
                          onFocus={handleFocus}
                        /> 
                    </td>

                    <td>
                    <input
                          className="form-control"
                          type="text"
                          style={{"width" : "200px" , "height"  : "36px"}}
                          name="EmpId"
                       //   ref={(el) => (Descriptions.current[index] = el)}
                          value={EmpId}
                          
                          disabled
                        /> 
                    </td>

                    <td>
                    <td>
                    <input
                          className="form-control"
                          type="text"
                          style={{"width" : "200px" , "height"  : "36px"}}
                          name="IsDone"
                       //   ref={(el) => (Descriptions.current[index] = el)}
                          value={IsDone}
                          
                          disabled
                        /> 
                    </td>

                    

                    
                    </td>

            
            </tr>
            )
        })
   
    )
    
}
export default TableRowsAtt;